<template>
 <div class="content">
   <app-cells position="between">
     <h1 class="title title--theme">Трансфер (ID: {{ form.id }})</h1>
     <app-button
       v-if="!userIsAdmin && !readonlyCondition"
       theme="error"
       size="small"
       @click="onShowModal"
     >
       Удалить
     </app-button>
   </app-cells>
   <form @submit.prevent="onCheckForm">
     <app-grid>
       <template #item-1>
         <app-form-group required label="Тип события">
           <app-cells position="start" :indent="false">
             <label class="radio" :class="{ 'radio--readonly': readonlyCondition }">
               <span class="radio__text">Прибытие</span>
               <input
                 v-model="form.transfer_type"
                 type="radio"
                 :value="true"
               />
               <span class="radio__radiomark"></span>
             </label>
             <label class="radio" :class="{ 'radio--readonly': readonlyCondition }">
               <span class="radio__text">Убытие</span>
               <input
                 v-model="form.transfer_type"
                 type="radio"
                 :value="false"
               />
               <span class="radio__radiomark"></span>
             </label>
           </app-cells>
           <template #error>
             <div v-if="$v.form.transfer_type.$dirty && !$v.form.transfer_type.required">Обязательное поле</div>
           </template>
         </app-form-group>
         <app-form-group required label="Дата" label-for="date">
           <app-input
             v-model="form.date"
             v-mask="'99.99.9999'"
             :error="$v.form.date.$error"
             placeholder="дд.мм.гггг"
             autocomplete="off"
             id="date"
             @paste.native.prevent
             :readonly="readonlyCondition"
           />
           <template #error>
             <div v-if="$v.form.date.$dirty && !$v.form.date.underscorePresent">Заполните поле полностью</div>
             <div v-if="$v.form.date.$dirty && !$v.form.date.required">Обязательное поле</div>
           </template>
         </app-form-group>
         <app-form-group label="Время" label-for="time" required>
           <app-input
             v-model="form.time"
             v-mask="'99:99'"
             @paste.native.prevent
             :error="$v.form.time.$error"
             id="time"
             placeholder="00:00"
             autocomplete="off"
             :readonly="readonlyCondition"
           />
           <template #error>
             <div v-if="$v.form.time.$dirty && !$v.form.time.underscorePresent">Заполните поле полностью</div>
             <div v-if="$v.form.time.$dirty && !$v.form.time.required">Обязательное поле</div>
           </template>
         </app-form-group>
         <app-form-group label="Участники делегации" required>
           <v-select
             v-model="form.transfer_participants"
             :reduce="item => item.pk"
             :options="participant_options"
             label="last_name"
             placeholder="Выберите участников"
             class="select select--multiple"
             :class="{ 'select--error': $v.form.transfer_participants.$error }"
             multiple
             :readonly="readonlyCondition"
           >
             <template #open-indicator>
               <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                 <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                 <path
                   d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                   fill="#fff"></path>
               </svg>
             </template>
             <template #option="option">
               <div class="select__item d-center">{{ option.last_name }} {{ option.first_name }} {{ option.patronymic ? option.patronymic : '' }}</div>
             </template>
             <template #selected-option="option">
               <div class="selected d-center">{{ option.last_name }} {{ option.first_name }} {{ option.patronymic ? option.patronymic : '' }}</div>
             </template>
             <template #no-options>Ничего не найдено</template>
           </v-select>
           <template #error>
             <div v-if="$v.form.transfer_participants.$dirty && !$v.form.transfer_participants.required">Обязательное поле</div>
           </template>
         </app-form-group>
         <app-form-group label="Тип транспорта" required>
           <v-select
             v-model="form.transport_type"
             :options="transport_options"
             label="name"
             placeholder="Выберите значение"
             class="select"
             :class="{ 'select--error': $v.form.transport_type.$error }"
             :readonly="readonlyCondition"
           >
             <template #open-indicator>
               <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                 <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                 <path
                   d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                   fill="#fff"></path>
               </svg>
             </template>
             <template #option="option">
               <div class="select__item d-center">{{ option.name }}</div>
             </template>
             <template #selected-option="option">
               <div class="selected d-center">{{ option.name }}</div>
             </template>
             <template #no-options>Ничего не найдено</template>
           </v-select>
           <template #error>
             <div v-if="$v.form.transport_type.$dirty && !$v.form.transport_type.required">Обязательное поле</div>
           </template>
         </app-form-group>
         <template v-if="form.transport_type && form.transport_type.code === 'own'">
          <app-form-group label="Транспорт будет использоваться делегацией в дни фестиваля">
            <app-cells position="start" :indent="false">
              <label class="radio" :class="{ 'radio--readonly': readonlyCondition }">
                <span class="radio__text">Да</span>
                <input
                  v-model="form.will_transportation_be_used"
                  type="radio"
                  :value="true"
                />
                <span class="radio__radiomark"></span>
              </label>
              <label class="radio" :class="{ 'radio--readonly': readonlyCondition }">
                <span class="radio__text">Нет</span>
                <input
                  v-model="form.will_transportation_be_used"
                  type="radio"
                  :value="false"
                />
                <span class="radio__radiomark"></span>
              </label>
            </app-cells>
          </app-form-group>
           <app-form-group label="Необходим пропуск под стекло">
             <app-cells position="start" :indent="false">
               <label class="radio" :class="{ 'radio--readonly': readonlyCondition }">
                 <span class="radio__text">Да</span>
                 <input
                   v-model="form.need_pass"
                   type="radio"
                   :value="true"
                 />
                 <span class="radio__radiomark"></span>
               </label>
               <label class="radio" :class="{ 'radio--readonly': readonlyCondition }">
                 <span class="radio__text">Нет</span>
                 <input
                   v-model="form.need_pass"
                   type="radio"
                   :value="false"
                 />
                 <span class="radio__radiomark"></span>
               </label>
             </app-cells>
           </app-form-group>
           <app-form-group label="Полный гос номер с регионом" label-for="plate_number">
             <app-input
               v-model="form.plate_number"
               id="plate_number"
               placeholder="Введите значение"
               :readonly="readonlyCondition"
             />
           </app-form-group>
           <app-form-group label="Марка и модель АТС" label-for="car_model">
             <app-input
               v-model="form.car_model"
               id="car_model"
               placeholder="Введите значение"
               :readonly="readonlyCondition"
             />
           </app-form-group>
           <app-form-group label="Необходима стоянка в дни фестиваля">
             <app-cells position="start" :indent="false">
               <label class="radio" :class="{ 'radio--readonly': readonlyCondition }">
                 <span class="radio__text">Да</span>
                 <input
                   v-model="form.need_parking"
                   type="radio"
                   :value="true"
                 />
                 <span class="radio__radiomark"></span>
               </label>
               <label class="radio" :class="{ 'radio--readonly': readonlyCondition }">
                 <span class="radio__text">Нет</span>
                 <input
                   v-model="form.need_parking"
                   type="radio"
                   :value="false"
                 />
                 <span class="radio__radiomark"></span>
               </label>
             </app-cells>
           </app-form-group>
           <app-form-group label="Необходимо проживание для водителей (если они не включены в состав делегации)">
             <app-cells position="start" :indent="false">
               <label class="radio" :class="{ 'radio--readonly': readonlyCondition }">
                 <span class="radio__text">Да</span>
                 <input
                   v-model="form.need_accomodation_drivers"
                   type="radio"
                   :value="true"
                 />
                 <span class="radio__radiomark"></span>
               </label>
               <label class="radio" :class="{ 'radio--readonly': readonlyCondition }">
                 <span class="radio__text">Нет</span>
                 <input
                   v-model="form.need_accomodation_drivers"
                   type="radio"
                   :value="false"
                 />
                 <span class="radio__radiomark"></span>
               </label>
             </app-cells>
           </app-form-group>
           <app-form-group label="Необходимо питание для водителей (если они не включены в состав делегации)">
             <app-cells position="start" :indent="false">
               <label class="radio" :class="{ 'radio--readonly': readonlyCondition }">
                 <span class="radio__text">Да</span>
                 <input
                   v-model="form.need_food_drivers"
                   type="radio"
                   :value="true"
                 />
                 <span class="radio__radiomark"></span>
               </label>
               <label class="radio" :class="{ 'radio--readonly': readonlyCondition }">
                 <span class="radio__text">Нет</span>
                 <input
                   v-model="form.need_food_drivers"
                   type="radio"
                   :value="false"
                 />
                 <span class="radio__radiomark"></span>
               </label>
             </app-cells>
           </app-form-group>
         </template>
       </template>
       <template #item-2>
         <app-form-group v-if="form.transport_type && form.transport_type !== 4" label="Номер рейса" label-for="number">
           <app-input
            v-model="form.number"
            id="number"
            placeholder="Введите значение"
            :readonly="readonlyCondition"
           />
         </app-form-group>
         <app-form-group v-if="form.transport_type === 4" label="Водители">
           <div class="form-table" :class="{'form-table--readonly': readonlyCondition}">
             <div class="form-table__head">
               <div class="form-table__item">Фамилия, имя, отчество</div>
               <div class="form-table__item">Серия паспорта</div>
               <div class="form-table__item">Номер паспорта</div>
             </div>
             <div
               v-for="(item, index) in form.drivers_list"
               :key="index"
               class="form-table__row"
             >
               <button v-if="index > 0" @click="onDeleteItem(index)" class="link link--absolute"
                       type="button">
               </button>
               <div class="form-table__item">
                 <input
                   :id="`fio${index}`"
                   type="text"
                   :name="`fio${index}`"
                   v-model.trim="item.fio"
                 />
               </div>
               <div class="form-table__item">
                 <input
                   :id="`series${index}`"
                   type="text"
                   :name="`series${index}`"
                   v-model.trim="item.series"
                 />
               </div>
               <div class="form-table__item">
                 <input
                   :id="`number${index}`"
                   type="text"
                   :name="`number${index}`"
                   v-model.trim="item.number"
                 />
               </div>
             </div>
           </div>
           <app-button size="link" class="btn--link-icon" @click="onCloneItem" type="button">Добавить строку</app-button>
         </app-form-group>
         <app-form-group label="ФИО контактного лица" label-for="contact_person" required>
           <app-input
             v-model="form.contact_person"
             id="contact_person"
             placeholder="Введите значение"
             :error="$v.form.contact_person.$error"
             :readonly="readonlyCondition"
           />
           <template #error>
             <div v-if="$v.form.contact_person.$dirty && !$v.form.contact_person.required">Обязательное поле</div>
           </template>
         </app-form-group>
         <app-form-group label="Телефон" required>
           <app-phone
             v-model="form.phone"
             :error="$v.form.phone.$dirty && (!$v.form.phone.required || (form.phone === 0))"
             @change.native="$v.form.phone.$touch()"
             @paste.native.prevent
             :readonly="readonlyCondition"
           />
           <template #error>
             <div v-if="$v.form.phone.$dirty && !$v.form.phone.required">Обязательное поле</div>
             <div v-if="$v.form.phone.$dirty && (form.phone === 0) && $v.form.phone.required">
               Неправильный формат номера
             </div>
           </template>
         </app-form-group>
         <app-form-group label="Примечания, в т.ч. описание негабаритного багажа" label-for="baggage">
           <app-textarea
            v-model="form.baggage"
            id="baggage"
            placeholder="Введите значение"
            :readonly="readonlyCondition"
           />
         </app-form-group>
       </template>
     </app-grid>
     <app-cells v-if="userIsAdmin || !readonlyCondition">
       <app-button
         ref="submit"
         :disabled="$v.form.$error"
       >
         Сохранить
       </app-button>
     </app-cells>
   </form>
   <modal
     name="delete-modal"
     :width="750"
     :height="'auto'"
     :scrollable="true"
     :adaptive="true"
   >
     <div class="modal">
       <div class="modal__title modal__title--center-black">Подтверждение действия</div>
       <p class="modal__text">
         Вы собираетесь удалить трансфер - ID: {{ form.id }}.
         Это действие невозможно будет отменить и данные будут удалены безвозвратно. Вы уверены, что хотите это сделать?
       </p>
       <app-cells position="center">
         <app-button size="small" @click="onDeleteTransfer" ref="submitDelete">Да</app-button>
         <app-button size="small" @click="onHideModal">Нет</app-button>
       </app-cells>
     </div>
   </modal>
 </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {getMembersList, getTransferType, putTransfer, getTransfer, deleteTransfer, getDelegationSettings} from '@/http'
import { dateFormatting } from '@/helpers'

const underscorePresent = value => {
  if (!value) return true
  else return !value.includes('_')
}

export default {
  name: 'TransferEdit',
  data() {
    return {
      form: {
        drivers_list: [
          {
            fio: '',
            series: '',
            number: '',
          }
        ]
      },
      participant_options: [],
      transport_options: [],
      delegation_settings: {},
    }
  },
  validations: {
    form: {
      transfer_type: { required },
      transfer_participants: { required },
      transport_type: { required },
      phone: { required },
      contact_person: { required },
      date: { required, underscorePresent },
      time: { required, underscorePresent },
    }
  },
  created() {
    getDelegationSettings()
      .then(response => {
        this.delegation_settings = response.data
      })
    getMembersList({page_size: 200})
      .then(response => {
        this.participant_options = response.data.results
      })
    getTransferType()
      .then(response => {
        this.transport_options = response.data
      })
    getTransfer(this.$route.params.id)
      .then(response => {
        this.form = {...response.data, date: dateFormatting(response.data.date, 'iso-to-normal-small'), transport_type: this.transport_options.find((option) => option.id === response.data.transport_type)}
      })
      .catch(() => {
        this.$router.push({ name: 'transfer-list' })
      })
  },
  computed: {
    userIsHead() {
      return this.$store.state.user_role === 'head'
    },
    userIsAdmin() {
      return this.$store.state.user_role === 'admin'
    },
    readonlyCondition() {
      return this.userIsHead && this.delegation_settings.transfers_done
    }
  },
  methods: {
    onShowModal() {
      this.$modal.show('delete-modal')
    },
    onHideModal() {
      this.$modal.hide('delete-modal')
    },
    onDeleteTransfer() {
      this.$refs.submitDelete.preload = true
      deleteTransfer(this.$route.params.id)
        .finally(() => {
          this.$refs.submitDelete.preload = false
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Трансфер удален'
          })
          this.$router.push({ name: 'transfer-list' })
        })
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      const form = {...this.form}
      form.date = dateFormatting(form.date, 'normal-to-iso-small')
      form.transport_type = form.transport_type.id
      putTransfer(this.$route.params.id, form)
        .finally(() => {
          this.$refs.submit.preload = false
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Трансфер изменен'
          })
          this.$router.push({ name: 'transfer-list' })
        })
    },
    onCloneItem() {
      this.form.drivers_list.push({fio: '', series: '', number: ''})
      this.$v.form.$touch()
    },
    onDeleteItem(index) {
      this.form.drivers_list.splice(index, 1)
    },
  }
}
</script>

